<template>
  <div class="about">
    <div class="page-body-full">
      <div class="page-body-inner">
        <section class="relative-parent-full fullscreen">
          <div class="text-container center-left">
            <div class="content">
              <h3 class="page-title">Our Story</h3>
              <h1 class="hero-header">A history of <br />beautiful homes</h1>
              <p class="hero-body-small bold">
                Russell Brown is an award-winning designer whose career has
                spanned over 45 years. After leaving the Derby College of Art,
                he was privileged to train with the finest of artisans in the
                craft of hand-painting.
              </p>
              <p class="hero-body-small">
                In 1980, inspired by a family history of entrepreneurship and
                natural affinity for design, Russell started Russell Brown
                Interiors, and within a year was hand-painting luxury furniture
                and kitchens for a renowned bespoke kitchen designer and maker.
              </p>
              <p class="hero-body-small">
                In the ensuing years Russell has continued to build his
                reputation for exceptional quality and distinctive, luxurious
                design. He has draped curtains across the windows of stately
                homes, hand-painted libraries for peers and decorated beautiful
                apartments from Mayfair to Edinburgh, and across the Midlands.
              </p>
              <p class="hero-body-small">
                Russell Brown Interiors is synonymous with standards. The
                distinctive royal blue livery is as often outside a stately home
                as a private residence, designing stylish interiors at the most
                exclusive addresses.
              </p>

              <a
                href="#"
                v-scroll-to="'#start'"
                style="margin-top: 3em; display: inline-block"
              >
                <img
                  src="/flex-content/uploads/2020/11/downarrow.svg"
                  class="down"
                />
              </a>
            </div>
          </div>
          <div class="img-container-right">
            <div class="overlay" />
            <imgdivloader
              :classes="'image AppImage'"
              :ids="'top'"
              :lazySrc="'/flex-content/uploads/2020/11/IMG_4574-RussellBrown-denoise-scaled.jpg'"
            />
          </div>
        </section>
        <intersect
          :threshold="[0.25, 0.25]"
          @enter="bigNav(false)"
          @leave="bigNav(true)"
        >
          <div id="start">
            <section class="no-margin blue-section">
              <div
                class="text-center"
                style="max-width: 1000px; margin: 0 auto"
              >
                <img
                  class="fleur"
                  src="/flex-content/uploads/2020/11/fleurblue_Top.svg"
                /><br />
                <p class="quote">
                  “It's unwise to pay too much, but it's worse to pay too
                  little. When you pay too much, you lose a little money -
                  that's all. When you pay too little, you sometimes lose
                  everything, because the thing you bought was incapable of
                  doing the thing it was bought to do. The common law of
                  business balance prohibits paying a little and getting a lot -
                  it can't be done. If you deal with the lowest bidder, it is
                  well to add something for the risk you run, and if you do that
                  you will have enough to pay for something better.”
                </p>
                <p>John Ruskin 1819 - 1900</p>
              </div>
            </section>
            <section class="relative-parent-full fullscreen">
              <div class="text-container center-right">
                <div class="content">
                  <h1 class="hero-header">
                    Timeless <br />
                    design
                  </h1>
                  <p class="hero-body-small bold">
                    Exceptional design is timeless. True quality and
                    craftmanship are lasting, as much in a contemporary design
                    as in antique furnishings. Russell combines a love of the
                    luxurious flamboyance of previous decades with an
                    appreciation of modern, streamlined tastes to create
                    interiors that boast flair and style.
                  </p>
                  <p class="hero-body-small">
                    As a perfectionist, Russell believes that the only way to
                    ensure his high standards are met is to do the work himself.
                    When you work with Russell Brown Interiors, it’s Russell
                    Brown that you get. Your home will be finished when every
                    curtain drape, brushstroke and piece of furniture is in
                    place.
                  </p>
                  <p>
                    We are proud to create a beautiful space for you to call
                    home.
                  </p>
                  <div class="btn-container" id="left">
                    <router-link class="btn no-margin" id="cream" to="/our-work"
                      >Our Work
                      <div class="icon">
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          width="22.8"
                          height="18.051"
                          viewBox="0 0 22.8 18.051"
                        >
                          <g
                            id="left-arrow"
                            transform="translate(22.8 71.377) rotate(180)"
                          >
                            <path
                              id="Path_1"
                              data-name="Path 1"
                              d="M22.336,61.871H1.633l7.74-7.739A.475.475,0,0,0,8.7,53.46L.151,62.01a.475.475,0,0,0,0,.672h0l8.55,8.55a.475.475,0,0,0,.683-.66l-.012-.012-7.74-7.74h20.7a.475.475,0,0,0,0-.95Z"
                              transform="translate(-0.011 0)"
                            />
                            <path
                              id="Path_2"
                              data-name="Path 2"
                              d="M9.025,71.37a.475.475,0,0,1-.336-.139l-8.55-8.55a.475.475,0,0,1,0-.672l8.55-8.55a.475.475,0,0,1,.672.672L1.147,62.345l8.214,8.214a.475.475,0,0,1-.335.811Z"
                              transform="translate(0)"
                            />
                            <path
                              id="Path_3"
                              data-name="Path 3"
                              d="M22.333,246.135H.483a.475.475,0,1,1,0-.95h21.85a.475.475,0,0,1,0,.95Z"
                              transform="translate(-0.008 -183.315)"
                            />
                          </g>
                        </svg>
                      </div>
                    </router-link>
                  </div>
                </div>
              </div>
              <div class="img-container-left">
                <div class="overlay" />
                <imgdivloader
                  :classes="'image AppImage'"
                  :ids="'top'"
                  :lazySrc="'/flex-content/uploads/2020/11/IMG_0037-RussellBrown-denoise-scaled-e1606586388654.jpg'"
                />
              </div>
            </section>
          </div>
        </intersect>
      </div>
    </div>
    <footerdiv />
  </div>
</template>
<script>
import intersect from "vue-intersect";
import imgdivloader from "@/partials/ImgLoaderDiv.vue";
import footerdiv from "@/partials/footer.vue";
export default {
  data() {
    return {};
  },
  components: {
    imgdivloader,
    footerdiv,
    intersect,
  },
  mounted() {
    this.bigNav(true);
  },
  methods: {
    bigNav(value) {
      this.$store.commit("TOGGLE_NAVBAR_SIZE", value);
    },
  },
  computed: {
    pageData() {
      var routePath = this.$route.fullPath;

      if (routePath === "/") {
        var permaLink = "/";
      } else {
        permaLink = this.$route.path + "/";
      }

      return this.$store.getters.getPage(permaLink);
    },
  },
  metaInfo() {
    return {
      title: this.pageData.yoast_meta.yoast_wpseo_title,
      link: [
        {
          rel: "canonical",
          href: "https://russell-brown.co.uk" + this.$route.path,
        },
      ],
      meta: [
        {
          vmid: "description",
          name: "description",
          content: this.pageData.yoast_meta.yoast_wpseo_metadesc,
        },

        // Twitter Meta Tags
        {
          vmid: "twitter:card",
          name: "twitter:card",
          content: "summary_large_image",
        },

        {
          vmid: "twitter:title",
          name: "twitter:title",
          content: this.pageData.yoast_meta.yoast_wpseo_title,
        },

        {
          vmid: "twitter:image",
          name: "twitter:image",
          content: this.pageData.fimg_url,
        },

        {
          vmid: "twitter:description",
          name: "twitter:description",
          content: this.pageData.yoast_meta.yoast_wpseo_metadesc,
        },
        //Open Graph Integrations
        {
          vmid: "og:image",
          property: "og:image",
          content: this.pageData.fimg_url,
        },
        {
          vmid: "og:title",
          property: "og:title",
          content: this.pageData.yoast_meta.yoast_wpseo_title,
        },
        { vmid: "og:locale", property: "og:locale", content: "en_GB" },
        { vmid: "og:type", property: "og:type", content: "article" },
        { vmid: "og:url", property: "og:url", content: this.pageData.link },
        {
          vmid: "og:site_name",
          property: "og:site_name",
          content: "Russell Brown Interiors",
        },
        {
          vmid: "og:description",
          property: "og:description",
          content: this.pageData.yoast_meta.yoast_wpseo_metadesc,
        },
        {
          vmid: "article:modified_time",
          name: "article:modified_time",
          content: this.pageData.modified_gmt + "+00:00",
        },
        {
          vmid: "article:published_time",
          name: "article:published_time",
          content: this.pageData.date_gmt + "+00:00",
        },
        {
          vmid: "og:updated_time",
          name: "og:updated_time",
          content: this.pageData.modified_gmt + "+00:00",
        },
      ],
    };
  },
};
</script>
